import { useRuntimeConfig } from '#imports'
import * as Sentry from '@sentry/nuxt';

if (process.env.NODE_ENV !== 'test') {
  Sentry.init({
    dsn: useRuntimeConfig().public.sentryDsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      })
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.3,
    tracePropagationTargets: [
      useRuntimeConfig().public.baseURL
    ],
    tracesSampleRate: 0.3,
  })
}

